import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useLocalStorage from 'use-local-storage';
import { Exercise } from '../models/Exercise';
import { WorkoutTemplate } from '../models/Workout';
import { weightLiftingExercises } from '../state/default-exercises';
import { Button } from './ui/button';

type Props = {
  onSave: (workout: WorkoutTemplate) => void
}

function NewWorkoutForm({ onSave }: Props) {
  const [name, setName] = useLocalStorage('NewWorkoutForm.name', 'workout')
  const id = name.toLowerCase().replace(/ /g, '-')
  const [exercises, setExercises] = useLocalStorage<Exercise[]>(`NewWorkoutForm.exercises.${id}`, [])
  const [newExerciseName, setNewExerciseName] = useState('')
  const navigate = useNavigate()
  return (
    <main className="p-2">
      <nav className="flex flex-row gap-2">
        <Button variant="secondary" onClick={() => {
          navigate(-1)
        }}>Back</Button>
        <input autoFocus className="flex-1 font-bold text-2xl p-1" type="text" value={name} onChange={(e) => {
          setName(e.target.value)
        }} />
      </nav>
      <section className="mt-2">
        {/* Add an exercise */}
        <div className="flex flex-row gap-2 mb-2">
          <input id={`NewWorkoutForm.exercises`} className="flex-1 p-1 border border-gray-300 rounded-md" type="text" required value={newExerciseName} onChange={(e) => {
            setNewExerciseName(e.target.value)
          }} list="exercises" />
          <datalist id="exercises">
            {weightLiftingExercises.filter(e => !exercises.some(ex => ex.name === e.name)).map(w => {
              return <option key={w.name + w.muscleGroup + w.equipment} value={w.name} />
            })}
          </datalist>
          <Button disabled={newExerciseName.length === 0} className="ml-2" onClick={() => {
            setExercises([...exercises, { id: newExerciseName.toLowerCase().replace(/ /g, '-'), name: newExerciseName, sets: [] }])
            setNewExerciseName('')
            // focus back on the exercise input
            document.getElementById(`NewWorkoutForm.exercises`)?.focus()
          }}>Add exercise</Button>
        </div>
        <ul className="list-none p-0 m-0 flex flex-col gap-2 mb-2">
          {exercises.map((exercise, i) => (
            <li className="flex flex-row justify-between items-center border border-gray-300 p-2 rounded-md" key={i}>{exercise.name}
              <Button variant="secondary" onClick={() => {
                setExercises(exercises.filter(e => e.id !== exercise.id))
              }}>X</Button></li>
          ))}
        </ul>
      </section>
      <div className="fixed bottom-0 left-0 right-0 p-2 flex flex-col items-center">
        {exercises.length === 0 && <p className="text-gray-500">Add some exercises to your workout</p>}
        <Button disabled={exercises.length === 0} className="w-full" onClick={() => {
          onSave({ id, name, exercises })
          setName('workout')
          setExercises([])
        }}>Add workout</Button>
      </div>
    </main>
  )
}

export default NewWorkoutForm
