export type Feeling = 'too-easy' | 'good' | 'hard' | 'failed'
export type FeelingDisplay = 'Too Easy' | 'Good' | 'Hard' | 'Failed'

export function getFeelingIcon(feeling: Feeling): React.ReactNode {
  switch (feeling) {
    case 'too-easy':
      return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-antenna-bars-2"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M6 18l0 -3" /><path d="M10 18l0 .01" /><path d="M14 18l0 .01" /><path d="M18 18l0 .01" /></svg>
    case 'good':
      return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-antenna-bars-3"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M6 18l0 -3" /><path d="M10 18l0 -6" /><path d="M14 18l0 .01" /><path d="M18 18l0 .01" /></svg>
    case 'hard':
      return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-antenna-bars-4"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M6 18l0 -3" /><path d="M10 18l0 -6" /><path d="M14 18l0 -9" /><path d="M18 18l0 .01" /></svg>
    case 'failed':
      return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-antenna-bars-5"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M6 18l0 -3" /><path d="M10 18l0 -6" /><path d="M14 18l0 -9" /><path d="M18 18l0 -12" /></svg>
    default:
      throw new Error(`Unknown feeling: ${feeling}`)
  }
}

export function getFeelingDisplay(feeling: Feeling): FeelingDisplay {
  switch (feeling) {
    case 'too-easy':
      return 'Too Easy'
    case 'good':
      return 'Good'
    case 'hard':
      return 'Hard'
    case 'failed':
      return 'Failed'
    default:
      throw new Error(`Unknown feeling: ${feeling}`)
  }
}

export const FEELINGS = [
  'too-easy',
  'good',
  'hard',
  'failed'
] as const

export type SetFeedback = {
  exerciseId: string;
  setNumber: number;
  feeling: Feeling;
  injured: boolean;
  comment?: string;
}
