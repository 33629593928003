import { WorkoutTemplate } from "../models/Workout";

export const legDay: WorkoutTemplate = {
  id: "leg-day",
  name: "Leg Day",
  exercises: [
    {
      name: "Goblet squat",
      id: "goblet-squat",
      sets: [
        { reps: 10, weightLbs: 45 },
        { reps: 10, weightLbs: 45 },
        { reps: 10, weightLbs: 50 },
        { reps: 10, weightLbs: 50 },
      ]
    },
    {
      name: "RDLs",
      id: "rdls",
      sets: [
        { reps: 10, weightLbs: 35 },
        { reps: 10, weightLbs: 40 },
        { reps: 10, weightLbs: 40 },
        { reps: 10, weightLbs: 45 },
      ]
    },
    {
      name: "Bulgarian split squat",
      id: "bulgarian-split-squat",
      sets: [
        { reps: 10, weightLbs: 0 },
        { reps: 10, weightLbs: 20 },
        { reps: 10, weightLbs: 25 },
      ]
    },
    {
      name: "Calf raises",
      id: "calf-raises",
      sets: [
        { reps: 10, weightLbs: 0 },
        { reps: 10, weightLbs: 0 },
        { reps: 10, weightLbs: 0 },
      ]
    },
    {
      name: "Hip thrust",
      id: "hip-thrust",
      sets: [
        { reps: 12, weightLbs: 70 },
        { reps: 12, weightLbs: 75 },
        { reps: 12, weightLbs: 80 },
      ]
    }
  ]
}

export const newUpperBodyDay: WorkoutTemplate = {
  id: "combined-upper-body-day",
  name: "Combined Upper Body",
  exercises: [
    {
      name: "Lat pull down (or pull ups)",
      id: "lat-pull-down",
      sets: [
        { reps: 10, weightLbs: 165 },
        { reps: 10, weightLbs: 165 },
        { reps: 10, weightLbs: 176 },
        { reps: 10, weightLbs: 176 },
      ]
    },
    {
      name: "Bench",
      id: "bench",
      sets: [
        { reps: 10, weightLbs: 35 },
        { reps: 10, weightLbs: 35 },
        { reps: 8, weightLbs: 35 },
        { reps: 8, weightLbs: 35 },
      ]
    },
    {
      name: "Bent over dumbbell row",
      id: "bent-over-dumbbell-row",
      sets: [
        { reps: 10, weightLbs: 35 },
        { reps: 10, weightLbs: 35 },
        { reps: 10, weightLbs: 35 },
        { reps: 10, weightLbs: 35 },
        { reps: 8, weightLbs: 40 },
      ]
    },
    {
      name: "Shoulder press",
      id: "shoulder-press",
      sets: [
        { reps: 10, weightLbs: 17.5 },
        { reps: 10, weightLbs: 17.5 },
        { reps: 8, weightLbs: 20 },
        { reps: 8, weightLbs: 20 },
        { reps: 8, weightLbs: 22.5 },
      ]
    },
    {
      name: "Wall bicep curls superset with raises",
      id: "wall-bicep-curls-superset-with-raises",
      sets: [
        { reps: 10, weightLbs: 12.5 },
        { reps: 10, weightLbs: 12.5 },
        { reps: 10, weightLbs: 15 },
      ]
    },
    {
      name: "Dumbbell lateral side raise",
      id: "dumbbell-lateral-side-raise",
      sets: [
        { reps: 10, weightLbs: 12.5 },
        { reps: 10, weightLbs: 12.5 },
        { reps: 10, weightLbs: 12.5 },
      ]
    },
    {
      name: "Tricep push down",
      id: "tricep-push-down",
      sets: [
        { reps: 10, weightLbs: 31 },
        { reps: 10, weightLbs: 31 },
        { reps: 10, weightLbs: 33 },
      ]
    }
  ],
}
