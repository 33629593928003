import { endOfWeek, getDay, getWeek, isWithinInterval, startOfWeek, subWeeks } from 'date-fns';
import { groupBy, sortBy } from 'lodash';
import { WorkoutLogEntry } from '../models/Workout';
import { Checkbox } from './ui/checkbox';

type Props = {
  history: WorkoutLogEntry[];
}

const WEEK = ['S', 'M', 'T', 'W', 'T', 'F', 'S']
const NOW = new Date(); // TODO: doesn't update ever... long running tabs problem? whatever this is a prototype
const START_OF_WEEK = startOfWeek(NOW)
const END_OF_WEEK = endOfWeek(NOW)

function findStreak(history: WorkoutLogEntry[]): { streak: number, inDanger: boolean } {
  // finds longest number of consecutive weeks worked out
  // ensure it's sorted by datetime
  const sortedHistory = sortBy(history, (h) => -h.startTime)
  if (sortedHistory.length <= 0) {
    return { streak: 0, inDanger: false };
  }
  let prevTime = sortedHistory[0].startTime
  const isFirstThisWeek = isWithinInterval(prevTime, {
    start: START_OF_WEEK,
    end: END_OF_WEEK,
  })
  const isFirstLastWeek = isWithinInterval(prevTime, {
    start: subWeeks(START_OF_WEEK, 1),
    end: subWeeks(END_OF_WEEK, 1),
  })
  if (!isFirstThisWeek && !isFirstLastWeek) {
    return { streak: 0, inDanger: false };
  }
  const inDanger = !isFirstThisWeek && isFirstLastWeek
  let streak = 1;
  for (const entry of history.slice(1)) {
    // compare entry and prevTime
    const prevWeek = getWeek(prevTime)
    const thisWeek = getWeek(entry.startTime)
    const diff = Math.abs(prevWeek - thisWeek)
    if (diff > 1) {
      // streak broken
      break;
    }
    streak += diff;
    prevTime = entry.startTime;
  }
  return { streak, inDanger };
}

function getStreakComment(streak: number, inDanger: boolean) {
  if (inDanger) {
    return `Save your ${streak} week streak!`
  }
  if (streak === 0) {
    return `Work out to start a weekly streak.`
  }
  return `You're on a ${streak} week streak, nice.`
}

function WeekSummary({ history }: Props) {
  const thisWeek = history.filter(h => {
    return isWithinInterval(h.startTime, {
      start: START_OF_WEEK,
      end: END_OF_WEEK
    })
  })
  const entriesByDay = groupBy(thisWeek, (w) => {
    return getDay(w.startTime);
  })
  const { streak, inDanger } = findStreak(history)
  return (
    <div className="flex flex-col gap-2">
      <span>
        {getStreakComment(streak, inDanger,)}
      </span>
      <div className='flex w-full justify-between'>
        {WEEK.map((w, i) => {
          const entries = entriesByDay[i]
          const isToday = getDay(NOW) === i
          return <div key={i} className={`flex flex-col items-center gap-2 py-1 px-2 rounded-lg ${isToday ? 'border-1 border-primary' : ''}`}>
            <span className={`${isToday ? "text-foreground" : "text-muted-foreground"} text-xs`}>{w}</span>
            <div>
              {<Checkbox checked={!!entries} className="rounded-full w-5 h-5" />}
            </div>
          </div>
        })}
      </div >
    </div>
  )
}

export default WeekSummary
