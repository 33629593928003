import { useEffect } from 'react'
import { Link, Route, Routes, useNavigate } from 'react-router'
import useLocalStorage from 'use-local-storage'
import * as workoutAnalytics from './analytics/workout-analytics'
import NewWorkoutForm from './components/NewWorkoutForm'
import { Exercise, ExerciseTemplate } from './models/Exercise'
import { ActiveWorkout, WorkoutLogEntry } from './models/Workout'
import ExerciseTemplatesPage from './pages/exercises/ExerciseTemplatesPage'
import NewExerciseTemplateForm from './pages/exercises/NewExerciseTemplateForm'
import Home from './pages/Home'
import WorkoutLogPage from './pages/workout/WorkoutLogPage'
import WorkoutLogsPage from './pages/workout/WorkoutLogsPage'
import WorkoutPage from './pages/workout/WorkoutPage'
import WorkoutTemplatePage from './pages/workout/WorkoutTemplatePage'
import WorkoutTemplatesPage from './pages/workout/WorkoutTemplatesPage'
import { addExerciseToWorkout, addSetToExercise, endActiveWorkout, removeExerciseFromWorkout, removeSetFromExercise, startNewWorkout, updateSetInExercise } from './state/active-workout'
import { DEFAULT_WORKOUTS } from './state/default-workouts'
import { ACTIVE_WORKOUT_LOCAL_STORAGE_KEY, EXERCISES_LOCAL_STORAGE_KEY, WORKOUT_TEMPLATES_LOCAL_STORAGE_KEY } from './state/local-storage-keys'
import { removeWorkoutLogEntry, WORKOUT_LOG_LOCAL_STORAGE_KEY } from './state/workout-log-entries'
import { removeWorkoutTemplate, updateWorkoutTemplate } from './state/workout-template'

function App() {
  const navigate = useNavigate()
  // reset scroll position to top when navigating to a new page
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [navigate])

  const [exerciseTemplates, setExerciseTemplates] = useLocalStorage<ExerciseTemplate[]>(EXERCISES_LOCAL_STORAGE_KEY, [], {
    parser: (str) => {
      const parsed = JSON.parse(str) as ExerciseTemplate[]
      return parsed;
    }
  })
  const [workoutTemplates, setWorkoutTemplates] = useLocalStorage(WORKOUT_TEMPLATES_LOCAL_STORAGE_KEY, DEFAULT_WORKOUTS);
  const [workoutLogEntries, setWorkoutLogEntries] = useLocalStorage<WorkoutLogEntry[]>(WORKOUT_LOG_LOCAL_STORAGE_KEY, [], {
    parser: (str) => {
      const parsed = JSON.parse(str) as {
        id: string
        workoutId: string
        name: string
        exercises: Exercise[]
        startTime: string
        endTime: string
      }[]
      return parsed.map(p => {
        return {
          id: p.id,
          workoutId: p.workoutId,
          name: p.name,
          exercises: p.exercises,
          startTime: new Date(p.startTime),
          endTime: new Date(p.endTime),
        }
      })
    }
  })
  const [activeWorkout, setActiveWorkout] = useLocalStorage<ActiveWorkout | undefined>(ACTIVE_WORKOUT_LOCAL_STORAGE_KEY, undefined, {
    parser: (str) => {
      const parsed = JSON.parse(str) as ActiveWorkout | undefined
      return parsed ? {
        ...parsed,
        startTime: new Date(parsed.startTime)
      } : undefined
    }
  });
  const handleEndActiveWorkout = () => {
    const setsCompleted = activeWorkout?.exercises.reduce((acc, exercise) => {
      return acc + exercise.sets.filter(s => s.done).length
    }, 0)
    const setsRemaining = activeWorkout?.exercises.reduce((acc, exercise) => {
      return acc + exercise.sets.filter(s => !s.done).length
    }, 0)
    if (!confirm(`Are you sure you want to end the workout? You have ${setsCompleted} sets completed and ${setsRemaining} sets remaining.`)) {
      return;
    }
    const newEntry = endActiveWorkout(activeWorkout!)
    setWorkoutLogEntries(existingEntries => {
      if (!existingEntries) {
        return [newEntry]
      }
      return [...existingEntries, newEntry]
    })
    setActiveWorkout(undefined)

    setWorkoutTemplates(existingTemplates => {
      const newTemplates = updateWorkoutTemplate(existingTemplates || [], activeWorkout!)
      return newTemplates
    })
    workoutAnalytics.workoutEnded(activeWorkout!, new Date().getTime() - activeWorkout!.startTime.getTime())
    // navigate to a summary of the active workout completed
    navigate(`/workouts/log/${newEntry.id}`)
  }
  return (
    <Routes >
      <Route path="/" element={<Home workoutLogs={workoutLogEntries} workouts={workoutTemplates} activeWorkout={activeWorkout} onEndActiveWorkout={handleEndActiveWorkout} />} />
      <Route path="exercises">
        <Route index element={<ExerciseTemplatesPage exerciseTemplates={exerciseTemplates} />} />
        <Route path="new" element={<NewExerciseTemplateForm onSubmit={(template) => {
          setExerciseTemplates((existingTemplates) => {
            if (!existingTemplates) {
              return [template]
            }
            return [...existingTemplates, template]
          })
          navigate(`/exercises`)
        }} />} />
      </Route>
      <Route path="workouts">
        <Route index element={<WorkoutTemplatesPage workouts={workoutTemplates} />} />
        <Route path=":id" element={<WorkoutTemplatePage workoutLogs={workoutLogEntries} workouts={workoutTemplates} onStart={(workout) => {
          // start a new workout
          setActiveWorkout(startNewWorkout(workout))
          workoutAnalytics.workoutStarted(workout)
          navigate(`/workouts/active`)
        }} onDelete={(workout) => {
          setWorkoutTemplates(existingTemplates => removeWorkoutTemplate(existingTemplates || [], workout.id))
          navigate(`/workouts`)
        }} activeWorkout={activeWorkout} />} />
        <Route path="active" element={activeWorkout ? <WorkoutPage
          activeWorkout={activeWorkout!}
          onEndActiveWorkout={handleEndActiveWorkout}
          onAddSet={(set, exerciseName) => {
            setActiveWorkout(existingWorkout =>
              addSetToExercise(existingWorkout!, exerciseName, set)
            )
          }}
          onRemoveSet={(index, exerciseName) => {
            setActiveWorkout(existingWorkout =>
              removeSetFromExercise(existingWorkout!, exerciseName, index)
            )
          }}
          onSetChange={(index, set, exerciseName) => {
            const wasDone = activeWorkout!.exercises.find(e => e.name === exerciseName)!.sets[index].done
            if (!wasDone && set.done) {
              // marked done for the first time
              workoutAnalytics.setCompleted(activeWorkout!, set)
            }
            setActiveWorkout(existingWorkout =>
              updateSetInExercise(existingWorkout!, exerciseName, index, set)
            )
          }}
          onRemoveExercise={(exerciseId) => {
            // are you sure?
            if (!confirm(`Are you sure you want to remove ${activeWorkout!.exercises.find(e => e.id === exerciseId)!.name}?`)) {
              return;
            }
            setActiveWorkout(existingWorkout =>
              removeExerciseFromWorkout(existingWorkout!, exerciseId)
            )
            navigate('/workouts/active')
          }}
          onAddExercise={(exerciseName) => {
            setActiveWorkout(existingWorkout =>
              addExerciseToWorkout(existingWorkout!, exerciseName)
            )
          }}
        /> : <div>No active workout</div>} />
        <Route path="new" element={<NewWorkoutForm onSave={(workout) => {
          setWorkoutTemplates(existingTemplates => {
            if (!existingTemplates) {
              return [workout]
            }
            return [...existingTemplates, workout]
          })
          navigate(`/workouts/${workout.id}`)
        }} />} />
        <Route path="log" element={<WorkoutLogsPage workoutLogs={workoutLogEntries} />} />
        <Route path="log/:id" element={<WorkoutLogPage workoutLogs={workoutLogEntries} onRemoveWorkoutLog={id => {
          setWorkoutLogEntries(existingEntries => removeWorkoutLogEntry(existingEntries || [], id))
          navigate('/workouts/log')
        }} />} />
      </Route>
      <Route path="*" element={<main style={{ textAlign: 'center', padding: '2em' }}>
        <p>404 Not found</p>
        <Link to="/">Go back home</Link>
      </main>} />
    </Routes>
  )
}

export default App
