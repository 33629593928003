import { Button } from './ui/button';

type Props = {
  tabs: string[]
  activeTab: string;
  onSelect: (tab: string) => void;
}

function ExerciseTabs({ tabs, activeTab, onSelect }: Props) {
  return (
    <>
      <div className="flex gap-1 overflow-auto">
        {tabs.map((tab) => {
          // take the first three letters
          const label = tab.substr(0, 3)
          const isActive = tab === activeTab
          return <Button
            key={tab}
            variant={isActive ? 'default' : 'secondary'}
            style={{ flexGrow: isActive ? 2 : 1 }}
            onClick={() => onSelect(tab)}
          >{isActive ? tab : label}</Button>
        })}
      </div >
    </>
  )
}

export default ExerciseTabs
