export const weightLiftingExercises = [
  // Chest Exercises
  { name: "Bench Press", muscleGroup: "Chest", equipment: "Barbell" },
  { name: "Incline Bench Press", muscleGroup: "Chest", equipment: "Barbell" },
  { name: "Decline Bench Press", muscleGroup: "Chest", equipment: "Barbell" },
  { name: "Dumbbell Press", muscleGroup: "Chest", equipment: "Dumbbells" },
  { name: "Chest Fly", muscleGroup: "Chest", equipment: "Dumbbells" },
  { name: "Cable Fly", muscleGroup: "Chest", equipment: "Cable Machine" },
  { name: "Push-Up", muscleGroup: "Chest", equipment: "Bodyweight" },

  // Back Exercises
  { name: "Deadlift", muscleGroup: "Back", equipment: "Barbell" },
  { name: "Pull-Up", muscleGroup: "Back", equipment: "Bodyweight" },
  { name: "Lat Pulldown", muscleGroup: "Back", equipment: "Cable Machine" },
  { name: "Bent-Over Row", muscleGroup: "Back", equipment: "Barbell" },
  { name: "Dumbbell Row", muscleGroup: "Back", equipment: "Dumbbells" },
  { name: "Seated Cable Row", muscleGroup: "Back", equipment: "Cable Machine" },
  { name: "Face Pull", muscleGroup: "Back", equipment: "Cable Machine" },

  // Shoulder Exercises
  { name: "Overhead Press", muscleGroup: "Shoulders", equipment: "Barbell" },
  { name: "Dumbbell Shoulder Press", muscleGroup: "Shoulders", equipment: "Dumbbells" },
  { name: "Lateral Raise", muscleGroup: "Shoulders", equipment: "Dumbbells" },
  { name: "Front Raise", muscleGroup: "Shoulders", equipment: "Dumbbells" },
  { name: "Reverse Fly", muscleGroup: "Shoulders", equipment: "Dumbbells" },
  { name: "Shrugs", muscleGroup: "Shoulders", equipment: "Dumbbells" },

  // Arm Exercises
  { name: "Bicep Curl", muscleGroup: "Arms", equipment: "Dumbbells" },
  { name: "Hammer Curl", muscleGroup: "Arms", equipment: "Dumbbells" },
  { name: "Preacher Curl", muscleGroup: "Arms", equipment: "Barbell" },
  { name: "Tricep Dips", muscleGroup: "Arms", equipment: "Bodyweight" },
  { name: "Tricep Pushdown", muscleGroup: "Arms", equipment: "Cable Machine" },
  { name: "Skull Crushers", muscleGroup: "Arms", equipment: "Barbell" },
  { name: "Concentration Curl", muscleGroup: "Arms", equipment: "Dumbbells" },

  // Leg Exercises
  { name: "Squat", muscleGroup: "Legs", equipment: "Barbell" },
  { name: "Front Squat", muscleGroup: "Legs", equipment: "Barbell" },
  { name: "Lunges", muscleGroup: "Legs", equipment: "Dumbbells" },
  { name: "Leg Press", muscleGroup: "Legs", equipment: "Machine" },
  { name: "Leg Curl", muscleGroup: "Legs", equipment: "Machine" },
  { name: "Leg Extension", muscleGroup: "Legs", equipment: "Machine" },
  { name: "Calf Raise", muscleGroup: "Legs", equipment: "Machine" },

  // Core Exercises
  { name: "Plank", muscleGroup: "Core", equipment: "Bodyweight" },
  { name: "Hanging Leg Raise", muscleGroup: "Core", equipment: "Bodyweight" },
  { name: "Cable Woodchopper", muscleGroup: "Core", equipment: "Cable Machine" },
  { name: "Russian Twist", muscleGroup: "Core", equipment: "Dumbbells" },
  { name: "Crunches", muscleGroup: "Core", equipment: "Bodyweight" },
  { name: "Ab Rollout", muscleGroup: "Core", equipment: "Ab Wheel" }
];
