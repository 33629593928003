
import { ChevronRight } from 'lucide-react';
import { Link } from 'react-router';
import { ExerciseTemplate } from '../models/Exercise';
import { WorkoutTemplate } from '../models/Workout';
import { Card, CardDescription, CardHeader, CardTitle } from './ui/card';

type Props = {
  workout: WorkoutTemplate;
}

function WorkoutCard({ workout }: Props) {
  return (
    <Link to={`/workouts/${workout.id}`}>
      <div className="relative">
        <Card>
          <CardHeader>
            <CardTitle>{workout.name}</CardTitle>
            <CardDescription>
              <span className="font-bold">{workout.exercises.length} exercises</span> &middot;{' '}
              {workout.exercises.map((e: ExerciseTemplate) => e.name).join(', ')}
            </CardDescription>
          </CardHeader>
        </Card>
        {/* big sideways chevron positioned relative to the card on the right */}
        <div className="absolute right-0 top-0 bottom-0 flex items-center justify-center">
          <ChevronRight className="w-5 h-5 mr-2" />
        </div>
      </div>
    </Link>
  )
}

export default WorkoutCard
