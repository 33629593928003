import { v4 as uuidv4 } from 'uuid';
import { ESet } from '../models/ESet';
import { ActiveWorkout, WorkoutLogEntry, WorkoutTemplate } from '../models/Workout';

export function addExerciseToWorkout(
  activeWorkout: ActiveWorkout,
  exerciseName: string
): ActiveWorkout {
  return {
    ...activeWorkout,
    exercises: [...activeWorkout.exercises, {
      id: uuidv4(),
      name: exerciseName,
      sets: [],
    }]
  }
}


export function startNewWorkout(workout: WorkoutTemplate): ActiveWorkout {
  return {
    id: workout.id,
    name: workout.name,
    exercises: workout.exercises.map(e => ({ ...e, sets: e.sets.map(s => ({ ...s, done: false, feedback: undefined })) })),
    startTime: new Date(),
  };
}

export function addSetToExercise(
  activeWorkout: ActiveWorkout,
  exerciseName: string,
  set: ESet
): ActiveWorkout {
  return {
    ...activeWorkout,
    exercises: activeWorkout.exercises.map(e => {
      if (e.name === exerciseName) {
        return {
          ...e,
          sets: [...e.sets, set]
        };
      }
      return e;
    })
  };
}

export function removeSetFromExercise(
  activeWorkout: ActiveWorkout,
  exerciseName: string,
  index: number
): ActiveWorkout {
  return {
    ...activeWorkout,
    exercises: activeWorkout.exercises.map(e => {
      if (e.name === exerciseName) {
        return {
          ...e,
          sets: e.sets.filter((_, i) => i !== index)
        };
      }
      return e;
    })
  };
}

export function updateSetInExercise(
  activeWorkout: ActiveWorkout,
  exerciseName: string,
  index: number,
  set: ESet
): ActiveWorkout {
  const newWorkout = {
    ...activeWorkout,
    exercises: activeWorkout.exercises.map(e => {
      if (e.name === exerciseName) {
        return {
          ...e,
          sets: e.sets.map((s, i) => i === index ? set : s)
        };
      }
      return e;
    })
  };
  return newWorkout;
}

export function endActiveWorkout(workout: ActiveWorkout): WorkoutLogEntry {
  return {
    ...workout,
    id: uuidv4(),
    workoutId: workout.id,
    endTime: new Date()
  };
}

export function removeExerciseFromWorkout(
  activeWorkout: ActiveWorkout,
  exerciseId: string
): ActiveWorkout {
  return {
    ...activeWorkout,
    exercises: activeWorkout.exercises.filter(e => e.id !== exerciseId)
  };
}
