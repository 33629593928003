import { useState } from 'react'
import { SetTemplate } from '../models/ESet'

type Props = {
  onNewSets: (sets: SetTemplate[]) => void
}

const REGEX = () => /(\d+(?:\.\d+)?)\s*\*\s*(\d+(?:\.\d+)?)\s*\*\s*(\d+(?:\.\d+)?)/

function parseSetNotation(value: string): SetTemplate[] | null {
  // matches "2 * 10 * 100" and "2 * 10 * 2.5"
  const match = value.match(REGEX())

  if (!match) {
    return null
  }
  const n = parseInt(match[1], 10)
  const reps = parseInt(match[2], 10)
  // can be a float or an integer
  const weightLbs = parseFloat(match[3])
  return Array(n).fill({ reps, weightLbs })
}

function SetNotationInput({ onNewSets }: Props) {
  const [error, setError] = useState('')
  const [inputValue, setInputValue] = useState('')
  return (
    <form onSubmit={(e) => {
      e.preventDefault()
      const sets = parseSetNotation(inputValue)
      if (!sets) {
        setError('Please enter a valid set notation like "3*10*100".')
        return
      }
      onNewSets(sets!)
      setError('')
      setInputValue('')
    }}>
      <div style={{ display: 'flex', gap: '1em', alignItems: 'center' }}>
        <div style={{ display: 'flex', gap: '0.5em', flexDirection: 'column', flexGrow: 1 }}>
          <label htmlFor="setNotation">Add Sets</label>
          <input type="tel" id="setNotation" value={inputValue} placeholder="3*10*100" onChange={(e) => {
            setInputValue(e.target.value)
          }} />
        </div>
        <button type="submit">Add Sets</button>
      </div>
      <p style={{ fontSize: '0.8em', color: 'marlboro-gray' }}>E.g. "3*10*100" adds 3 sets of 10 reps at 100 lbs.</p>
      <p style={{ color: 'darkred' }}>
        {error}
      </p>
    </form>
  )
}

export default SetNotationInput
