import { WorkoutTemplate } from "../models/Workout";

export const pushDay: WorkoutTemplate = {
  id: "push",
  name: "Push",
  exercises: [
    {
      name: "Chest Press",
      id: "chest-press",
      sets: [
        { reps: 10, weightLbs: 80 },
        { reps: 10, weightLbs: 80 },
        { reps: 10, weightLbs: 90 },
        { reps: 8, weightLbs: 90 },
      ]
    },
    {
      name: "Incline Fly",
      id: "incline-fly",
      sets: [
        { reps: 10, weightLbs: 20 },
        { reps: 10, weightLbs: 20 },
        { reps: 10, weightLbs: 30 },
        { reps: 10, weightLbs: 30 },
      ]
    },
    {
      name: "Arnold Press",
      id: "arnold-press",
      sets: [
        { reps: 10, weightLbs: 40 },
        { reps: 10, weightLbs: 40 },
        { reps: 10, weightLbs: 40 },
        { reps: 10, weightLbs: 50 },
      ]
    },
    {
      name: "Single Triceps Extension",
      id: "single-triceps-extension",
      sets: [
        { reps: 10, weightLbs: 10 },
        { reps: 10, weightLbs: 12.5 },
        { reps: 10, weightLbs: 12.5 },
        { reps: 10, weightLbs: 15 },
      ]
    }
  ]
}

export const pullDay: WorkoutTemplate = {
  id: "pull",
  name: "Pull",
  exercises: [
    {
      name: "Bent Over Row",
      id: "bent-over-row",
      sets: [
        { reps: 10, weightLbs: 40 },
        { reps: 10, weightLbs: 40 },
        { reps: 10, weightLbs: 45 },
        { reps: 10, weightLbs: 45 },
      ]
    },
    {
      name: "Reverse Fly",
      id: "reverse-fly",
      sets: [
        { reps: 10, weightLbs: 5 },
        { reps: 10, weightLbs: 5 },
        { reps: 10, weightLbs: 10 },
        { reps: 10, weightLbs: 10 },
      ]
    },
    {
      name: "Shrug",
      id: "shrug",
      sets: [
        { reps: 10, weightLbs: 80 },
        { reps: 10, weightLbs: 80 },
        { reps: 10, weightLbs: 90 },
        { reps: 10, weightLbs: 90 },
      ]
    },
    {
      name: "Bicep Curl",
      id: "bicep-curl",
      sets: [
        { reps: 10, weightLbs: 20 },
        { reps: 10, weightLbs: 20 },
        { reps: 10, weightLbs: 20 },
        { reps: 8, weightLbs: 25 },
      ]
    }
  ]
}
