import * as Sentry from '@sentry/react'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router'
import { Toaster } from 'sonner'
import App from './App.tsx'
import Analytics from './components/Analytics.tsx'
import ErrorBoundary from './components/ErrorBoundary.tsx'
import { ThemeProvider } from './components/theme-provider.tsx'
import './index.css'

Sentry.init({
  dsn: "https://a12eee6b50dc393e3361b4e6f9a48ddf@o4508973954105344.ingest.us.sentry.io/4508973959151616"
})

// version number check in local storage
const VERSION = '1.0.2'
const version = localStorage.getItem('version')
if (version !== VERSION) {
  // clear local storage
  alert("Resetting data to new version " + VERSION)
  localStorage.clear()
  localStorage.setItem('version', VERSION)
  window.location.href = '/'
}

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
      <ErrorBoundary>
        <BrowserRouter>
          <Analytics />
          <App />
          <Toaster duration={Infinity} />
        </BrowserRouter>
      </ErrorBoundary>
    </ThemeProvider>
  </StrictMode>,
)
