import { useState } from "react"
import { v4 as uuidv4 } from 'uuid'
import SetNotationInput from "../../components/SetNotationInput"
import { Button } from "../../components/ui/button"
import { SetTemplate } from "../../models/ESet"
import { ExerciseTemplate } from "../../models/Exercise"
type Props = {
  onSubmit: (name: ExerciseTemplate) => void
}

function NewExerciseTemplateForm({ onSubmit }: Props) {
  const [name, setName] = useState('')
  const [sets, setSets] = useState<SetTemplate[]>([])
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onSubmit({ name, id: uuidv4(), sets })
  }
  return (
    <div>
      <h1>New Exercise Template</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name">Name</label>
          <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} />
        </div>

        <Button type="submit" style={{ position: 'absolute', bottom: 0, right: 0, margin: 10, left: 0 }}>Create</Button>
      </form>
      <SetNotationInput
        onNewSets={(sets) => {
          // add the new sets to the existing sets
          setSets((existingSets) => [...existingSets, ...sets])

        }}
      />
      <div>
        <h2>Sets</h2>
        <ul>
          {sets.map((set, i) => (
            <li key={i}>{set.reps}x{set.weightLbs} <button className="danger" onClick={() => {
              setSets((existingSets) => existingSets.filter((_, j) => j !== i))
            }}>X</button></li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default NewExerciseTemplateForm
