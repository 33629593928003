import { Link } from 'react-router-dom'
import { ExerciseTemplate } from '../../models/Exercise'

type Props = {
  exerciseTemplates: ExerciseTemplate[]
}

function ExerciseTemplatesPage({ exerciseTemplates }: Props) {
  return (
    <div>
      <nav style={{ display: 'flex', gap: 10, alignItems: 'center', justifyContent: 'space-between' }}>
        <Link style={{ paddingLeft: "0.5em", paddingRight: "0.5em" }} className="button secondary" to="/">Back</Link>
        <h1 style={{ margin: 0, fontSize: "1.2em" }}>Exercise Templates</h1>
        <Link style={{ paddingLeft: "0.5em", paddingRight: "0.5em" }} className="button primary" to="/exercises/new">New</Link>
      </nav>
      {exerciseTemplates.length === 0 && (
        <span style={{ color: 'gray' }}>No exercise templates found</span>
      )}
      <ul>
        {exerciseTemplates.map(exerciseTemplate => (
          <li key={exerciseTemplate.id}>{exerciseTemplate.name}
            <ol>
              {exerciseTemplate.sets.map((set, i) => (
                <li key={i}>{set.reps}x{set.weightLbs}</li>
              ))}
            </ol>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default ExerciseTemplatesPage
