export const ACTIVE_EXERCISE_LOCAL_STORAGE_KEY = "active-exercise"
export const WORKOUT_TEMPLATES_LOCAL_STORAGE_KEY = "workout-templates"
export const EXERCISES_LOCAL_STORAGE_KEY = "exercises"
export const WORKOUT_LOG_LOCAL_STORAGE_KEY = "workout-log"
export const ACTIVE_WORKOUT_LOCAL_STORAGE_KEY = "active-workout"

export const ALL_LOCAL_STORAGE_KEYS = [
  WORKOUT_TEMPLATES_LOCAL_STORAGE_KEY,
  EXERCISES_LOCAL_STORAGE_KEY,
  WORKOUT_LOG_LOCAL_STORAGE_KEY,
  ACTIVE_WORKOUT_LOCAL_STORAGE_KEY,
]
