import { MenuIcon } from 'lucide-react';
import { useState } from 'react';
import { Link } from 'react-router';
import useLocalStorage from 'use-local-storage';
import ExerciseTabs from "../../components/ExerciseTabs";
import SetRow from "../../components/SetRow";
import { Button } from '../../components/ui/button';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from '../../components/ui/dialog';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger } from '../../components/ui/dropdown-menu';
import WorkoutContext from "../../components/WorkoutContext";
import { ESet } from "../../models/ESet";
import { ActiveWorkout } from "../../models/Workout";

type Props = {
  activeWorkout: ActiveWorkout;
  onEndActiveWorkout: () => void;
  onAddSet: (set: ESet, exerciseName: string, workoutName: string) => void;
  onRemoveSet: (index: number, exerciseName: string, workoutName: string) => void;
  onSetChange: (index: number, set: ESet, exerciseName: string, workoutName: string) => void;
  onRemoveExercise: (exerciseId: string) => void;
  onAddExercise: (exerciseName: string) => void;
}

const getActiveExerciseNameKey = (workoutName: string) => `active-exercise-${workoutName}`

function WorkoutPage({ onEndActiveWorkout, activeWorkout, onAddSet, onRemoveSet, onSetChange, onRemoveExercise, onAddExercise }: Props) {
  const [activeExerciseName, setActiveExerciseName] = useLocalStorage<string | undefined>(getActiveExerciseNameKey(activeWorkout.name), activeWorkout?.exercises?.[0]?.name || undefined);
  const activeExercise = activeWorkout.exercises.find(e => e.name === activeExerciseName)
  const [newExerciseName, setNewExerciseName] = useState<string | undefined>(undefined)
  const [isOpen, setIsOpen] = useState(false)
  const sets = activeExercise?.sets
  if (!activeExercise || !sets) {
    const firstExercise = activeWorkout.exercises[0]
    setActiveExerciseName(firstExercise.name)
    return <>
      <div className="flex flex-col gap-4 p-4">
        <div className="flex gap-4 justify-between">
          <h1 className="text-2xl font-bold">Loading...</h1>
        </div>
      </div>
    </>
  }
  return (
    <div className="flex flex-col justify-between h-full">
      <main className="flex flex-col gap-4 p-4">
        <div className='flex gap-4 justify-between'>
          <div className="flex gap-4">
            <Button variant="secondary" asChild>
              <Link to="/">Back</Link>
            </Button>
            <h1 className="text-2xl font-bold">{activeWorkout.name}</h1>
          </div>
          <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="secondary">
                  <MenuIcon className="w-4 h-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                {activeWorkout.exercises.map(e => {
                  const isActive = e.name === activeExercise!.name
                  return <DropdownMenuItem key={e.id} onClick={() => {
                    onRemoveExercise(e.id)
                  }}>
                    Remove {e.name}{isActive ? <span className="text-muted-foreground"> (active)</span> : ""}
                  </DropdownMenuItem>
                })}
                <DropdownMenuSeparator />
                <DialogTrigger asChild>
                  <DropdownMenuItem>
                    Add Exercise
                  </DropdownMenuItem>
                </DialogTrigger>
              </DropdownMenuContent>
            </DropdownMenu>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Add Exercise</DialogTitle>
              </DialogHeader>
              <DialogDescription className="flex flex-col gap-4">
                <input autoFocus type="text" placeholder="Exercise name" className="text-2xl text-primary border-b-2 border-b-muted-foreground border-dashed" onChange={(e) => {
                  setNewExerciseName(e.target.value)
                }} />
                <Button onClick={() => {
                  if (newExerciseName) {
                    onAddExercise(newExerciseName)
                    setNewExerciseName(undefined)
                    setIsOpen(false)
                    setActiveExerciseName(newExerciseName)
                  }
                }}>Add Exercise</Button>
              </DialogDescription>
            </DialogContent>
          </Dialog>
        </div>
        <ExerciseTabs onSelect={(tab) => {
          setActiveExerciseName(tab)
        }} tabs={activeWorkout!.exercises.map(e => e.name)} activeTab={activeExercise!.name} />
        <div className='flex flex-col gap-4'>
          <section className='flex flex-col gap-4'>
            {sets.map(({ reps, weightLbs, done, feedback }, i) => {
              return <SetRow feedback={feedback} exerciseName={activeExercise!.name} exerciseId={activeExercise!.id} isDone={done} onChange={(s) => {
                onSetChange(i, s, activeExercise!.name, activeWorkout!.name)
              }} n={i + 1} key={activeWorkout.name! + activeExercise!.name + i} reps={reps} weightLbs={weightLbs} onRemove={() => {
                onRemoveSet(i, activeExercise!.name, activeWorkout!.name)
              }}
              />
            })}
          </section>
          <Button className="button" variant="secondary" onClick={() => {
            // get the last set of the active exercise and copy it
            const lastSet = sets[sets.length - 1] || { reps: 0, weightLbs: 0, done: false, feedback: undefined }
            const newSet: ESet = {
              ...lastSet,
              done: false,
              feedback: undefined,
            }
            onAddSet(newSet, activeExercise!.name, activeWorkout!.name)
          }}>Add set</Button>
        </div >
      </main>
      <aside className="sticky bottom-0 left-0 right-0">
        <WorkoutContext onEnd={() => {
          setActiveExerciseName(undefined)
          onEndActiveWorkout()
        }} onNextExercise={() => {
          // wrap around to the first exercise if we're at the last one
          const nextExerciseIndex = (activeWorkout.exercises.findIndex(e => e.name === activeExercise!.name) + 1) % activeWorkout.exercises.length
          setActiveExerciseName(activeWorkout.exercises[nextExerciseIndex].name)
        }} activeWorkout={activeWorkout}
        />
      </aside>
    </div>
  )
}

export default WorkoutPage
