import { useState } from 'react'
import { toast } from 'sonner'
import { ESet } from '../models/ESet'
import { Feeling, FEELINGS, getFeelingDisplay, SetFeedback } from '../models/SetFeedback'
import { Button } from './ui/button'
import { Checkbox } from './ui/checkbox'
import { Input } from './ui/input'
import { ToggleGroup, ToggleGroupItem } from './ui/toggle-group'

type Props = {
  eSet: ESet
  toastId: string | number,
  exerciseName: string,
  exerciseId: string,
  setNumber: number,
  onFeedbackChange: (feedback: SetFeedback) => void
}

function SetFeedbackForm({ eSet, toastId, onFeedbackChange, exerciseName, exerciseId, setNumber }: Props) {
  const [feeling, setFeeling] = useState<Feeling | undefined>(eSet.feedback?.feeling)
  const [injured, setInjured] = useState<boolean>(eSet.feedback?.injured ?? false)
  const [comment, setComment] = useState<string | undefined>(undefined);
  return (
    <div className="flex flex-col gap-2 bg-card p-3 rounded-md shadow-lg border-1 border-color-background">
      <div className="flex flex-row items-center gap-1 justify-between">
        <h2 className="text-lg font-bold">How was it?</h2>
        <Button variant="ghost" onClick={() => {
          toast.dismiss(toastId)
        }}>
          X
        </Button>
      </div>
      <p className="text-sm text-muted-foreground">
        You did {eSet.reps} reps @ {eSet.weightLbs} lbs of {exerciseName}. Nice.
      </p>
      <ToggleGroup
        type="single"
        onValueChange={(value) => {
          setFeeling(value as Feeling)
        }}
      >
        {FEELINGS.map((feeling) => {
          return <ToggleGroupItem key={feeling} className="border-1 p-3 data-[state=on]:bg-foreground data-[state=on]:border-foreground data-[state=on]:text-background" value={feeling}>{getFeelingDisplay(feeling)}</ToggleGroupItem>
        })}
      </ToggleGroup>
      <div>
        <label>
          Comment <span className="text-muted-foreground italic">Optional</span>
          <Input value={comment ?? ""} onChange={(e) => {
            setComment(e.target.value || undefined)
          }} />
        </label>
      </div>
      <div className="flex flex-row items-center gap-1 justify-between">
        <label className="flex flex-row items-center gap-1">
          <Checkbox checked={injured} onCheckedChange={(checked) => {
            setInjured(checked === 'indeterminate' ? false : checked)
          }} />
          <span>Injured</span>
        </label>
        <Button variant="secondary" onClick={() => {
          if (!feeling) {
            throw new Error('No feeling selected')
          }
          const feedback: SetFeedback = {
            exerciseId: exerciseId,
            setNumber: setNumber,
            feeling: feeling,
            injured: injured,
            comment: comment
          }
          onFeedbackChange(feedback)
          toast.dismiss(toastId)
        }}>
          Save
        </Button>
      </div>
    </div>

  )
}

export default SetFeedbackForm
