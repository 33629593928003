import { displaySetGroup } from '../models/set-display'
import { WorkoutTemplate } from '../models/Workout'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from './ui/accordion'
import { Button } from './ui/button'
type Props = {
  workout: WorkoutTemplate
  onViewWorkout: () => void
}

function WorkoutTemplateCard({ workout, onViewWorkout }: Props) {
  return (
    <div >
      <Accordion type="single" collapsible>
        <AccordionItem value={workout.id}>
          <AccordionTrigger>
            <div className="flex flex-col">
              <h3 className="text-lg font-bold">{workout.name}</h3>
              <p className="text-sm text-muted-foreground">{workout.exercises.length} exercises - {workout.exercises.map(e => e.name).join(', ')}</p>
            </div>
          </AccordionTrigger>
          <AccordionContent>
            {/* display set groups, in two columns*/}
            <div className="grid grid-cols-2 gap-2">
              {workout.exercises.map(e => (
                <div className="flex flex-col gap-2 flex-1">
                  <h4 className="text-md font-bold">{e.name}</h4>
                  {displaySetGroup(e.sets).map((setGroup, i) => (
                    <div key={i}>
                      <h5 className="text-sm text-muted-foreground">{setGroup.numSets} x {setGroup.reps} @ {setGroup.weightLbs} lbs</h5>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
      <Button variant="secondary" className="w-full" onClick={onViewWorkout}>View</Button>
    </div>
  )
}

export default WorkoutTemplateCard
