import { useEffect } from "react";
import ReactGA from 'react-ga4';
import { useLocation } from "react-router-dom";

function Analytics() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.initialize('G-PS9E145280');
  }, [])
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location])
  return null;
}

export default Analytics
