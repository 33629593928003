import { SetTemplate } from "./ESet";

export type SetGroup = {
  numSets: number
  reps: number
  weightLbs: number
}

export type Volume = {
  numSets: number
  reps: number
  weightLbs: number
}

export function displaySetGroup(group: SetTemplate[]): Volume[] {
  const reduced = group.reduce<SetGroup[]>((acc, set) => {
    const last = acc[acc.length - 1]
    if (last && last.reps === set.reps && last.weightLbs === set.weightLbs) {
      last.numSets++
    } else {
      acc.push({ numSets: 1, reps: set.reps, weightLbs: set.weightLbs })
    }
    return acc
  }, [])
  return reduced
}

export function displayVolume(group: SetTemplate[]): Volume {
  return group.reduce<Volume>((acc, set) => {
    acc.numSets += 1
    acc.reps += set.reps
    acc.weightLbs += set.weightLbs
    return acc
  }, { numSets: 0, reps: 0, weightLbs: 0 })
} 
