import { Link } from 'react-router-dom';
import { Button } from '../../components/ui/button';
import { getEndedAgo } from '../../libs/date';
import { WorkoutLogEntry } from '../../models/Workout';
type Props = {
  workoutLogs: WorkoutLogEntry[];
}

function WorkoutLogsPage({ workoutLogs }: Props) {
  const workoutLogsByDay = workoutLogs.reduce((acc, workoutLog) => {
    const date = workoutLog.endTime.toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' })
    acc[date] = [...(acc[date] || []), workoutLog]
    return acc
  }, {} as Record<string, WorkoutLogEntry[]>)
  return (
    <main className="p-2 flex flex-col gap-4">
      <nav className="flex flex-row items-center gap-2">
        <Button variant="secondary" asChild>
          <Link to="/">Back</Link>
        </Button>
        <h1 className="text-2xl font-bold">Workout Logs</h1>
      </nav>
      {workoutLogs.length === 0 && <p>No workout logs found. Start and finish a workout to see your logs.</p>}
      {Object.entries(workoutLogsByDay).map(([date, workoutLogs]) => {
        const endedAgo = getEndedAgo(workoutLogs[0].endTime)
        return (
          <div key={date}>
            <h2 className="text-lg font-bold mb-0">{date} ({endedAgo})</h2>
            <ul className="mt-0 pt-0 list-none ml-0 pl-0 gap-2 flex flex-col">
              {workoutLogs.map((workoutLog) => (
                <li key={workoutLog.id}>
                  <Button variant="secondary" asChild className="w-full">
                    <Link to={`/workouts/log/${workoutLog.id}`}>{workoutLog.name}</Link>
                  </Button>
                </li>
              ))}
            </ul>
          </div>
        )
      })}
    </main>
  )
}

export default WorkoutLogsPage
