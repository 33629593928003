import { AccordionTrigger } from '@radix-ui/react-accordion';
import { useNavigate, useParams } from 'react-router-dom';
import WorkoutLogEntryDisplay from '../../components/WorkoutLogEntryDisplay';
import WorkoutTemplateDisplay from '../../components/WorkoutTemplateDisplay';
import { Accordion, AccordionContent, AccordionItem } from '../../components/ui/accordion';
import { Button } from '../../components/ui/button';
import { getEndedAgo } from '../../libs/date';
import { ActiveWorkout, WorkoutLogEntry, WorkoutTemplate } from '../../models/Workout';

type Props = {
  workouts: WorkoutTemplate[]
  workoutLogs: WorkoutLogEntry[];
  activeWorkout: ActiveWorkout | undefined
  onStart: (workout: WorkoutTemplate) => void
  onDelete: (workout: WorkoutTemplate) => void
}

function mostRecentlyEnded(w1: WorkoutLogEntry, w2: WorkoutLogEntry): number {
  return -(w1.endTime.getTime() - w2.endTime.getTime());
}

function WorkoutTemplatePage({ workouts, workoutLogs, onStart, activeWorkout, onDelete }: Props) {
  const { id } = useParams()
  const workout = workouts.find(w => w.id === id)
  const navigate = useNavigate()
  const workoutLog = workoutLogs.sort(mostRecentlyEnded).find(l => l.workoutId === id)
  if (!workout) {
    return <div className="text-muted-foreground">Workout not found</div>
  }
  return (
    <main className="flex flex-col gap-4 p-4">
      <nav className="flex justify-between items-center gap-4">
        <Button onClick={() => {
          navigate(-1)
        }} variant="secondary">Back</Button>
        <div className="flex flex-col min-content">
          <h1 className="text-2xl font-bold">{workout.name}</h1>
        </div>
        <Button disabled={!!activeWorkout} onClick={() => {
          onStart(workout)
        }}>Start</Button>
      </nav>
      {workoutLog && <div className='flex flex-col gap-2'>
        <p className="text-muted-foreground">Last workout {getEndedAgo(workoutLog.endTime)}</p>
        <WorkoutLogEntryDisplay workoutLog={workoutLog} />
      </div>}
      <Accordion type="single" collapsible>
        <AccordionItem value={workout.id}>
          <AccordionTrigger>
            <Button>Show template</Button>
          </AccordionTrigger>
          <AccordionContent>
            <WorkoutTemplateDisplay workout={workout} />
            <section>
              <Button className="w-full" variant="destructive" onClick={() => {
                onDelete(workout)
              }}>Delete</Button>
            </section>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </main>
  )
}

export default WorkoutTemplatePage
