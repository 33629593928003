import ReactGA from 'react-ga4';
import { ESet } from '../models/ESet';
import { ActiveWorkout, WorkoutTemplate } from '../models/Workout';

export function workoutStarted(workout: WorkoutTemplate) {
  ReactGA.event({
    category: 'workout',
    action: 'workout_started',
    label: workout.id
  }, {
    workout
  })
}

export function workoutEnded(workout: ActiveWorkout, duration: number) {
  ReactGA.event({
    category: 'workout',
    action: 'workout_ended',
    label: workout.id,
    value: duration
  }, {
    workout
  })
}

export function setCompleted(workout: WorkoutTemplate, set: ESet) {
  ReactGA.event({
    category: 'workout',
    action: 'set_completed',
    label: workout.id,
    value: set.weightLbs * set.reps
  }, {
    set
  })
}
