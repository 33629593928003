import Papa from "papaparse";
import { Link, useParams } from "react-router-dom";
import { toast } from "sonner";
import { Button } from "../../components/ui/button";
import WorkoutLogEntryDisplay from "../../components/WorkoutLogEntryDisplay";
import { WorkoutLogEntry } from "../../models/Workout";
type Props = {
  workoutLogs: WorkoutLogEntry[]
  onRemoveWorkoutLog: (id: string) => void
}

function WorkoutLogPage({ workoutLogs, onRemoveWorkoutLog }: Props) {
  const { id } = useParams()
  const workoutLog = workoutLogs.find(l => l.id === id)
  if (!workoutLog) {
    return <div>Workout log not found <Link to="/workout/log">Go back</Link></div>
  }
  return (
    <main className="p-2 flex flex-col gap-4">
      <nav className="flex flex-row items-center justify-between mb-4">
        <Button variant="secondary" asChild>
          <Link to="/workouts/log">Back</Link>
        </Button>
        <h1 className="text-2xl font-bold">{workoutLog.name}</h1>
        <Button variant="destructive" onClick={() => {
          onRemoveWorkoutLog(workoutLog.id)
        }}>Remove</Button>
      </nav>
      {/* export to csv button */}
      <Button variant="secondary" onClick={async () => {
        // CSV using papaparse
        const sets = workoutLog.exercises.reduce((acc, exercise) => {
          return acc.concat(exercise.sets.map(s => ({
            workout: workoutLog.name,
            workoutStartTime: workoutLog.startTime.toLocaleString(),
            workoutEndTime: workoutLog.endTime.toLocaleString(),
            exercise: exercise.name,
            reps: s.reps,
            weightLbs: s.weightLbs,
            done: s.done,
            feeling: s.feedback?.feeling ?? 'unknown',
            injured: s.feedback?.injured ?? false,
            comment: s.feedback?.comment ?? ''
          })))
        }, [] as {
          workout: string
          workoutStartTime: string
          workoutEndTime: string
          exercise: string
          reps: number
          weightLbs: number
          done: boolean
          feeling: string
          injured: boolean
          comment: string
        }[])
        const csv = Papa.unparse(sets)

        try {
          await navigator.share({
            title: workoutLog.name,
            text: `Workout: ${workoutLog.name}\n\n${csv}`,
            files: [new File([csv], `${workoutLog.name}.csv`, { type: 'text/csv' })]
          })
        } catch (e) {
          console.error(e)
          toast.error(`Failed to share workout log, ${e instanceof Error ? e.message : 'Unknown error'}`)
        }
      }}>Export to CSV</Button>
      <WorkoutLogEntryDisplay workoutLog={workoutLog} />
    </main>
  )
}

export default WorkoutLogPage
