import { Link, useNavigate } from 'react-router';
import { Button } from '../../components/ui/button';
import WorkoutTemplateCard from '../../components/WorkoutTemplateCard';
import { WorkoutTemplate } from '../../models/Workout';
type Props = {
  workouts: WorkoutTemplate[];
}

function WorkoutTemplatesPage({ workouts }: Props) {
  const navigate = useNavigate()
  return (
    <main className="p-2">
      <nav className="flex flex-row items-center gap-2 mb-2">
        <Button variant="secondary" asChild>
          <Link to="/">Back</Link>
        </Button>
        <h1 className="text-2xl font-bold">Workout Templates</h1>
      </nav>
      <ul className="list-none p-0 m-0 flex flex-col gap-2 mb-2">
        {workouts.map(workout => (
          <WorkoutTemplateCard key={workout.id} workout={workout} onViewWorkout={() => {
            navigate(`/workouts/${workout.id}`)
          }} />
        ))}
      </ul>
      <Button variant="secondary" className="w-full" asChild>
        <Link to="/workouts/new">Create New Workout</Link>
      </Button>
    </main>
  )
}

export default WorkoutTemplatesPage
