import { useTimeString } from "../libs/date";
import { ActiveWorkout } from "../models/Workout";
import { Button } from "./ui/button";
import { Progress } from "./ui/progress";

type Props = {
  activeWorkout: ActiveWorkout;
  onEnd: () => void;
  onNextExercise: () => void;
}

function WorkoutContext({ activeWorkout, onEnd, onNextExercise }: Props) {
  const timeString = useTimeString(activeWorkout)
  const setsCompleted = activeWorkout.exercises.reduce((acc, exercise) => acc + exercise.sets.filter(s => s.done).length, 0)
  const totalSets = activeWorkout.exercises.reduce((acc, exercise) => acc + exercise.sets.length, 0)
  const allAreCompleted = setsCompleted === totalSets
  return (
    <div className="pointer-events-none p-2 flex flex-col gap-1 backdrop-blur-sm">
      <div className="flex justify-between items-center">
        <Button variant="destructive" className="pointer-events-auto" onClick={() => {
          onEnd()
        }}>End</Button>
        <div className="flex flex-col items-center flex-grow px-4">
          <span className="text-2xl font-bold">{timeString}</span>
          <div className="flex flex-row items-center justify-between text-sm">
            <span className={allAreCompleted ? 'text-green-500' : 'text-primary'}>{setsCompleted}/{totalSets} sets</span>
          </div>
        </div>
        <Button className="pointer-events-auto" onClick={() => {
          onNextExercise()
          // Wrap the index to the first exercise
        }}>Next</Button>
      </div>
      <Progress className="[&>*]:bg-green-500 rounded-none" value={setsCompleted / totalSets * 100} />
    </div>
  )
}

export default WorkoutContext
