import { useCallback, useState } from "react";
import { toast } from "sonner";
import { ESet } from "../models/ESet";
import { getFeelingDisplay, getFeelingIcon, SetFeedback } from "../models/SetFeedback";
import SetFeedbackForm from "./SetFeedbackForm";
import { Button } from "./ui/button";

type Props = {
  n: number;
  reps: number;
  weightLbs: number;
  isDone: boolean;
  exerciseName: string;
  exerciseId: string;
  onRemove?: () => void;
  onChange?: (s: ESet) => void;
  feedback?: SetFeedback;
}

function SetRow({
  n,
  reps,
  weightLbs,
  isDone,
  feedback,
  onChange,
  onRemove,
  exerciseName,
  exerciseId
}: Props) {
  const [rawWeight, setRawWeight] = useState<string>(weightLbs?.toString());

  const handleSetChange = useCallback((s: ESet) => {
    if (!isDone) {
      toast.custom((id) => {
        return <SetFeedbackForm eSet={s} toastId={id} onFeedbackChange={(feedback) => {
          onChange?.({
            reps: s.reps,
            weightLbs: s.weightLbs,
            done: s.done,
            feedback,
          })
        }} exerciseName={exerciseName} exerciseId={exerciseId} setNumber={n} />
      })
    }
    onChange?.({
      reps: reps ?? 1,
      weightLbs: weightLbs ?? 0,
      done: !isDone,
    })
  }, [isDone, onChange, reps, weightLbs, exerciseName, n, exerciseId])
  return (
    <div className={`w-full p-2 rounded-md ${isDone ? 'bg-complete' : ''}`}>
      <div className={`flex items-center justify-between w-full p-2 rounded-md`} >
        {n && <span className="text-muted-foreground text-sm">{n}.</span>}
        <label>
          <span className="sr-only">Reps</span>
          <input
            type="number"
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                e.currentTarget.blur();
              }
            }}
            onFocus={(e) => {
              e.target.select();
            }} onChange={(e) => {
              onChange?.({
                reps: parseInt(e.target.value, 10),
                weightLbs: weightLbs ?? 0,
                done: isDone || false,
              })
            }} value={reps} className="w-12 text-center border-dashed border-b-2 border-muted-foreground" />
        </label>
        <span className="text-muted-foreground text-sm">x</span>
        <label className="flex flex-row items-center gap-1">
          <input
            className="w-12 text-center border-dashed border-b-2 border-muted-foreground"
            onFocus={(e) => {
              e.target.select();
            }}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                e.currentTarget.blur();
              }
            }}
            onChange={(e) => {
              setRawWeight(e.target.value);
              const weight = parseFloat(e.target.value);
              if (!isNaN(weight)) {
                onChange?.({
                  reps: reps ?? 1,
                  weightLbs: weight,
                  done: isDone || false,
                  feedback,
                })
              }
            }} value={rawWeight} />
          <span className={`${isDone ? "text-color-complete" : "text-muted-foreground"} text-sm`}>Lbs</span>
        </label>
        <Button className={`p-3 ${isDone ? 'bg-complete-foreground' : ''}`} variant={isDone ? "default" : "outline"} onClick={() => {
          handleSetChange({
            reps: reps ?? 1,
            weightLbs: weightLbs ?? 0,
            done: !isDone,
            feedback,
          })
        }}>
          ✓
        </Button>
        <Button className="p-3 text-muted-foreground" variant="secondary" onClick={() => {
          onRemove?.()
        }}>X</Button>
      </div>
      {/* TODO: make this a component */}
      {feedback && <span className="text-xs flex flex-row items-center gap-1">{getFeelingIcon(feedback?.feeling)} {getFeelingDisplay(feedback.feeling)} {feedback.injured ? <span className="text-red-500 font-bold"> - Injured</span> : ''}{feedback.comment ? <span className="italic">&middot; {feedback.comment}</span> : ""}</span>}
    </div>
  )
}

export default SetRow
