import { WorkoutTemplate } from "../models/Workout";
import { legDay, newUpperBodyDay } from "./jeni-workouts";
import { pullDay, pushDay } from "./tom-workouts";

export const DEFAULT_WORKOUTS: WorkoutTemplate[] = [
  legDay,
  newUpperBodyDay,
  pushDay,
  pullDay,
]
