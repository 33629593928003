
import { WorkoutTemplate } from '../models/Workout'
import { displaySetGroup, displayVolume } from '../models/set-display'

type Props = {
  workout: WorkoutTemplate
}

function WorkoutTemplateDisplay({ workout }: Props) {
  return (
    <section>
      <h2 className="text-muted-foreground text-sm uppercase">Exercises</h2>
      <ul className="flex flex-col gap-4">
        {workout.exercises?.map(exercise => {
          const volume = displayVolume(exercise.sets.map(set => ({ ...set, done: false })))
          // show details of the exercise
          return <li key={exercise.id}>
            <h3 className="text-lg font-bold">{exercise.name}</h3>
            <ol className="list-none p-0 m-0">
              {displaySetGroup(exercise.sets.map(set => ({ ...set, done: false }))).map((set, index) => (
                <li className="text-muted-foreground text-sm" key={index}>{set.numSets} x {set.reps} @ {set.weightLbs} lbs</li>
              ))}
            </ol>
            {/* line */}
            <hr className="my-2" />
            <p className="text-muted-foreground text-sm">{volume.numSets} sets, {volume.reps} reps, {volume.weightLbs} lbs total</p>
          </li>

        })}
      </ul>
    </section>
  )
}

export default WorkoutTemplateDisplay
