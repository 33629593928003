import { useEffect } from "react"

import { useState } from "react"
import { ActiveWorkout } from "../models/Workout"

// if less than an hour, show minutes, if less than a day, show hours, if more than a day, show days
export function getEndedAgo(endDate?: Date) {
  if (!endDate) return "In progress"
  const ms = new Date().getTime() - endDate.getTime()
  const seconds = Math.floor(ms / 1000)
  if (seconds < 10) return `just now`
  if (seconds < 60) return `${seconds} seconds ago`
  const minutes = Math.floor(seconds / 60)
  if (minutes < 60) return `${minutes} minutes ago`
  const hours = Math.floor(minutes / 60)
  if (hours < 24) return `${hours} hours ago`
  const days = Math.floor(hours / 24)
  return `${days} days ago`
}

export function useTimeString(activeWorkout?: ActiveWorkout) {
  const [end, setEnd] = useState(new Date());
  useEffect(() => {
    if (!activeWorkout) return
    // update the time every millisecond
    const interval = setInterval(() => {
      setEnd(new Date())
    }, 1000);
    return () => clearInterval(interval);
  }, [activeWorkout]);
  const msSinceStart = end.getTime() - (activeWorkout?.startTime?.getTime() || 0);
  // get the minutes and seconds
  const minutes = Math.floor(msSinceStart / 60000)
  const seconds = Math.floor((msSinceStart % 60000) / 1000)
  return msSinceStart > 0 ? `${minutes}:${seconds.toString().padStart(2, '0')}` : "00:00";
}
