import { getEndedAgo } from '../libs/date'
import { getFeelingDisplay, getFeelingIcon } from '../models/SetFeedback'
import { WorkoutLogEntry } from '../models/Workout'

type Props = {
  workoutLog: WorkoutLogEntry
}

function WorkoutLogEntryDisplay({ workoutLog }: Props) {
  return (
    <div className="flex flex-col gap-2">
      <p>{workoutLog.exercises.length} exercises</p>
      {/* completed vs total sets with a progress bar, broken down by exercise name */}
      {workoutLog.exercises.map(exercise => (
        <div key={exercise.name}>
          <h2 style={{ fontSize: '1em', paddingBottom: '0' }}>{exercise.name}</h2>
          <div style={{ display: 'flex', alignItems: 'center', gap: 10, marginBottom: '0.5em' }}>
            <div style={{ flex: 1, height: 10, backgroundColor: 'darkred' }}>
              <div style={{ height: 10, backgroundColor: 'limegreen', width: `${exercise.sets.filter(s => s.done).length / exercise.sets.length * 100}%` }} />
            </div>
          </div>
          {/* completed / total sets - completed / total weight in lbs - completed / total reps */}
          <p>{exercise.sets.filter(s => s.done).length}/{exercise.sets.length} sets - {exercise.sets.filter(s => s.done).map(s => s.weightLbs).reduce((a, b) => a + b, 0)}/{exercise.sets.map(s => s.weightLbs).reduce((a, b) => a + b, 0)} lbs - {exercise.sets.filter(s => s.done).map(s => s.reps).reduce((a, b) => a + b, 0)}/{exercise.sets.map(s => s.reps).reduce((a, b) => a + b, 0)} reps</p>
          {/* details about each set: reps x weight, done or not done with a checkmark or cross*/}
          {exercise.sets.map((s, i) => (
            <p className={'flex flex-row items-center gap-1'} key={i}>
              {s.reps}x{s.weightLbs} lbs {s.done ? '✅' : '❌'}
              {s.feedback && <span className="text-xs inline-flex flex-row items-center gap-1">{getFeelingIcon(s.feedback?.feeling)} {getFeelingDisplay(s.feedback.feeling)} {s.feedback.injured ? <span className="text-red-500 font-bold"> - Injured</span> : ''} {s.feedback.comment ? <span>{s.feedback.comment}</span> : ''}</span>}
            </p>
          ))}
          <hr style={{ margin: '0.5em 0' }} />
        </div>
      ))}

      <p>{workoutLog.exercises.map(e => e.sets.map(s => s.weightLbs).reduce((a, b) => a + b, 0)).reduce((a, b) => a + b, 0)} lbs lifted</p>
      <p>{Math.round((workoutLog.endTime.getTime() - workoutLog.startTime.getTime()) / 1000 / 60)} minutes long</p>
      <p>{workoutLog.startTime.toLocaleString()}</p>
      <p>{workoutLog.endTime.toLocaleString()}</p>
      <p>Ended {getEndedAgo(workoutLog.endTime)}</p>
    </div>
  )
}

export default WorkoutLogEntryDisplay
