import React from 'react';


interface ErrorBoundaryProps {
  children: React.ReactNode;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  state: { hasError: boolean; error: Error | null } = { hasError: false, error: null }

  static getDerivedStateFromError(error: Error) {
    console.error(error)
    return { hasError: true, error }
  }

  render() {
    if (this.state.hasError) {
      return <main style={{ textAlign: 'center', padding: '2em' }}>
        <h1>Something went wrong.</h1>
        <pre>
          <code>
            {this.state.error?.message}
          </code>
        </pre>
        <a href="/">Go back home</a>
      </main>
    }

    return this.props.children
  }
}

export default ErrorBoundary
