import { Link } from "react-router";
import { ModeToggle } from "../components/mode-toggle";
import { Button } from "../components/ui/button";
import WeekSummary from "../components/WeekSummary";
import WorkoutCard from "../components/WorkoutCard";
import { getEndedAgo, useTimeString } from "../libs/date";
import { ActiveWorkout, WorkoutLogEntry, WorkoutTemplate } from "../models/Workout";

type Props = {
  workouts: WorkoutTemplate[];
  workoutLogs: WorkoutLogEntry[];
  activeWorkout: ActiveWorkout | undefined;
  onEndActiveWorkout: () => void;
}

function Home({ workouts, workoutLogs, activeWorkout, onEndActiveWorkout }: Props) {
  const mostRecentWorkoutLogsSorted = workoutLogs.sort((a, b) => {
    if (a.endTime && b.endTime) {
      return b.endTime.getTime() - a.endTime.getTime()
    }
    if (a.endTime) return -1
    if (b.endTime) return 1
    return b.startTime.getTime() - a.startTime.getTime()
  })
  const mostRecentWorkoutLogs = mostRecentWorkoutLogsSorted.slice(0, 5)
  const timeString = useTimeString(activeWorkout)
  return (
    <>
      <div className="flex flex-col gap-4 p-4">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-bold">Workouts ({workouts.length})</h1>
          <ModeToggle />
        </div>
        <section>
          <WeekSummary history={workoutLogs} />
        </section>
        <section className="flex flex-col gap-4">
          {workouts.map((workout) => {
            return <WorkoutCard key={workout.name} workout={workout} />
          })}
          <Button variant="secondary" asChild>
            <Link to="/workouts">See all workouts</Link>
          </Button>
          <Button variant="secondary" asChild>
            <Link to="/exercises">
              <img src='barbell.svg' alt="barbell" />
              See exercises
            </Link>
          </Button>
        </section>
        <section>
          <h1 className="text-2xl font-bold">Workout Log</h1>
          <ul className="flex flex-col gap-2 py-2">
            {mostRecentWorkoutLogs.map((log) => {
              const endedAgo = getEndedAgo(log.endTime)
              const numberOfCompletedSets = log.exercises.reduce((acc, exercise) => {
                return acc + exercise.sets.filter(s => s.done).length
              }, 0)
              const totalSets = log.exercises.reduce((acc, exercise) => {
                return acc + exercise.sets.length
              }, 0)
              const totalMinutes = Math.round(Math.floor((log.endTime?.getTime() || new Date().getTime()) - log.startTime.getTime()) / 1000 / 60)
              return <li key={log.name + log.startTime}><b>{log.name}</b> - {endedAgo} - {numberOfCompletedSets}/{totalSets} sets - {totalMinutes} minutes - <Link className="text-muted-foreground" to={`/workouts/log/${log.id}`}>View Details</Link></li>
            })}
          </ul>
          <Button className="w-full" variant="secondary" asChild>
            <Link to="/workouts/log">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-logs"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M4 12h.01" /><path d="M4 6h.01" /><path d="M4 18h.01" /><path d="M8 18h2" /><path d="M8 12h2" /><path d="M8 6h2" /><path d="M14 6h6" /><path d="M14 12h6" /><path d="M14 18h6" /></svg>
              See logs</Link>
          </Button>
        </section>
        <section className="flex flex-col gap-2">
          <h1 className="text-2xl font-bold">Debug</h1>
          <Button className="w-full" variant="destructive" onClick={() => {
            const confirmed = window.confirm("Reset everything?")
            if (confirmed) {
              localStorage.clear()
              window.location.reload()
            }
          }}>Factory Reset</Button>
        </section>
      </div>
      {activeWorkout &&
        <div className="sticky bottom-0 left-0 right-0 pointer-events-none p-2 flex flex-row gap-1 justify-between items-center backdrop-blur-sm">
          <span>
            <Button className="pointer-events-auto bg-destructive text-white px-2 py-1 rounded-md" onClick={() => {
              onEndActiveWorkout()
            }}>End</Button>
          </span>
          <div className="flex flex-col items-center">
            <span className="text-2xl font-bold">{timeString}</span>
            <Link className="text-sm text-marlboro-gray" to={`/workouts/active`}>{activeWorkout.name}</Link>
          </div>
          <Button className="pointer-events-auto button" asChild>
            <Link to={`/workouts/active`}>Return</Link>
          </Button>
        </div>
      }
    </>
  )
}

export default Home
