import { ActiveWorkout } from "../models/Workout";

import { WorkoutTemplate } from "../models/Workout";

export function updateWorkoutTemplate(workoutTemplates: WorkoutTemplate[], workout: ActiveWorkout): WorkoutTemplate[] {
  return workoutTemplates.map(template => {
    if (template.id === workout.id) {
      return workout
    }
    return template
  })
}

export function removeWorkoutTemplate(workoutTemplates: WorkoutTemplate[], workoutId: string): WorkoutTemplate[] {
  return workoutTemplates.filter(template => template.id !== workoutId)
}

export function addWorkoutTemplate(workoutTemplates: WorkoutTemplate[], workout: WorkoutTemplate): WorkoutTemplate[] {
  return [...workoutTemplates, workout]
}
